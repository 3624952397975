import React from 'react';
import { graphql } from 'gatsby';
import BasicStructure from '../components/basic-structure/basic-structure';
import './projekt.css';
import { getWpBlock } from '../components/wp-blocks';
import Sidebar from '../components/sidebar/sidebar';
import ProjectMenu from '../components/side-bar-components/project-menu';
import TitleMetaTags from '../components/title-meta-tags/title-meta-tags';
import ContentContainer from '../components/content-container/content-container';
import Footer from '../components/footer/footer';
import Header from '../components/header/header';
import Menubar from '../components/menu/menubar';
import BurgerMenu from '../components/burgermenu/burgermenu';
import ScrollUp from '../components/scroll-up/scroll-up';
import { WpPage } from '../../generated/graphql';

interface Props {
  data: {
    wpPage: WpPage;
  };
}

function Projekt({ data }: Props): JSX.Element {
  const page = data.wpPage;
  const pageTitle = page?.title || '';
  const blocks = page?.blocks || [];
  // console.log('projekt', page);

  return (
    <BasicStructure>
      <Header>
        <TitleMetaTags title={pageTitle} />
        <Menubar styleClass="main-menu" />
        <BurgerMenu>
          <ProjectMenu id={page?.id} parentId={page?.parentId ?? ''} />
        </BurgerMenu>
      </Header>
      <Sidebar>
        <ProjectMenu id={page?.id} parentId={page?.parentId ?? ''} />
        <ScrollUp />
      </Sidebar>
      <TitleMetaTags title={pageTitle} />
      <ContentContainer>
        <section className="project-page">
          <h1 className="page-title">{pageTitle}</h1>
          {blocks.length > 0 &&
            blocks.map((block: any, index) => {
              const WpBlock = getWpBlock(block.__typename);
              const className = block?.attributes?.className ?? '';
              let customClass = '';

              if (
                className &&
                className.indexOf('styling-margin-bottom') >= 0
              ) {
                block.attributes.className = className.replace(
                  'styling-margin-bottom',
                  '',
                );
                customClass = 'styling-margin-bottom';
              }

              if (!WpBlock) return null;

              const blockKey = `${page.id}-${block.id ?? index}`;

              return (
                <div className={`content-block ${customClass}`} key={blockKey}>
                  <WpBlock block={block} />
                </div>
              );
            })}
        </section>
      </ContentContainer>
      <Footer />
    </BasicStructure>
  );
}

export const pageQuery = graphql`
  query ($pageId: String!) {
    wpPage(id: { eq: $pageId }) {
      id
      parentId
      title
      uri
      blocks {
        ... on WpCoreImageBlock {
          attributes {
            ... on WpCoreImageBlockAttributes {
              id
            }
          }
        }
        # any block
        __typename
        saveContent
        isDynamic
        dynamicContent
        ... on WpLazyblockProjectHighlightBlock {
          __typename
          attributes {
            lazyblock
            blockId
            highlights
          }
          name
        }
        ...WPColumns
        ... on WpCoreHeadingBlock {
          __typename
          originalContent
          attributes {
            ... on WpCoreHeadingBlockAttributes {
              className
            }
          }
        }
        ...WpCoreImageBlockFragment
        ...WpCoreGalleryBlockFragment
        ... on WpCoreGroupBlock {
          __typename
          innerBlocks {
            __typename
            ...WpCoreImageBlockForGalleryFragment
            ...WpCoreGalleryBlockFragment
            saveContent
            isDynamic
            dynamicContent
          }
        }
      }
    }
  }
`;

export default Projekt;
